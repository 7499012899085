export default {
    state:{
        markers: [],
        azsList: {},
        brandsSelected: [],
        allAZS: false
    },
    mutations: {
        mutateMarkersData(state, payload) {
            const updatedMarkers = [...payload];
            state.markers = updatedMarkers;
        },
        mutateAZSList(state, payload) {  
            state.azsList[payload.id] = payload.data;
        },
        mutateSelectedBrands(state, payload) {  
            state.brandsSelected = payload
        },
        mutateAllAZS(state, payload) {  
            state.allAZS = payload
        }
    },
    actions: {
        updateMarkers({ commit }, payload) {
            commit('mutateMarkersData', payload);
        },
        addAZS({ commit }, payload) {
            commit('mutateAZSList', payload);
        },
        updateSelectedBrands({ commit }, payload) {
            commit('mutateSelectedBrands', payload);
        },
        updateAllAZS({ commit }, payload) {
            commit('mutateAllAZS', payload);
        }
    },
    getters: {
        getMarkers(state) {
            return state.markers;
        },
        getAzs: (state) => (id) => {
            return state.azsList[id];
        },
        getSelectedBrands(state) {
            return state.brandsSelected;
        },
        getIsAllAZS(state) {
            return state.allAZS;
        }
    }
}