<template>
  <footer>
    <div class="container">
      <div class="flx flx-jc__sb">

        <div class="copy">
          &copy; 2009-{{new Date().getFullYear()}}
          <a target="_blank" rel="noopener" title="ДП «Держзовнішінформ»"  href="https://dzi.gov.ua">ДП "Держзовнішінформ"</a>          
        </div>
        
        <ul class="footer-links list-style__none flx">
          <li v-for='(link, index) in links' :key='index'>
            <router-link 
                class="footer-links__item" 
                v-if='link.routerLink'
                :to='link.url' exact>{{link.name}}
            </router-link>
            <a v-if='!link.routerLink'
              class="footer-links__item"
              target="_blank" 
              rel="noopener"
              :href='link.url'>
                {{link.name}}
            </a>            
          </li>
        </ul>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => ({
      links: [
        { name: 'Про проєкт', url: '/about.html', routerLink: false },
        { name: 'Україна', url: '/', routerLink: true },
        { name: 'Області', url: '/regions', routerLink: true },
        { name: 'Бренди', url: '/brends', routerLink: true },
        { name: 'Паливо', url: '/fuels', routerLink: true },
        { name: 'Траси', url: '/roads.html', routerLink: false }
      ]
  })
}
</script>
<style lang="scss">
  @import './src/assets/scss/sections/footer.scss';
</style>
