<template>
    <div class="brend-stella"
        :class="'brend_' + idBrend"
    >
        <div class="brend__logo"
            @click="selectBrend"
            title="Обрати бренд"
        >
            <div class="of-container">
                <img
                    class="of-container__image" 
                    :src="'http://azs.uapetrol.com/images/brends/brend' + idBrend + '.gif'">
            </div>
        </div>

        <ul class="brend__fuels">
            <li
                v-for="(fuel, index) in fuelsList"
                :key="index"
                :class="'brend-stella__item__' + fuel.id"
            >
                <input
                        type="checkbox"
                        :id="'brend-fuel_' + fuel.id"
                        :value="fuel.id" 
                        v-model="fuelSelected"
                    />

                    <label
                        class="brend-stella__item"
                        :for="'brend-fuel_' + fuel.id"
                        :key="fuel.id"
                        :title="fuel.name"
                    >
                        <span class="label" v-html="wrapBySpan(fuel.name)"></span>
                        <span class="price">{{fuel.price}}</span>
                    </label>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            idBrend: Number,
            fuelsList: Array,
            // fuelSelected: Array
        },
        data: ()=> ({
            fuelSelected: [],
            selectsdAll: true    
        }),
        watch: {
            fuelsList() {
                setTimeout(() => {
                    this.getFuelSlected();
                }, 50);
            },
            fuelSelected() {
                const fuelSelected = this.fuelSelected.length ? this.fuelSelected : [999];
                const data = {
                    idBrend: this.idBrend,
                    data: fuelSelected
                };
                this.$store.dispatch('updateBrendFuelSelected', data);
                this.$emit('changed');
            }
        },
        methods: {
            selectBrend() {
                this.$emit('selectBrend');
            },
            getFuelSlected() {
                const brendsData = this.$store.getters.getBrendData[this.idBrend];
                this.fuelSelected = brendsData ? brendsData.fuelSelected : [];                
            },
            wrapBySpan(name) {
                let arr = name.split(" ");
                const span_class = arr.length > 1 ? 'len__' + arr.length : '' ;
                return `<span class="${span_class}">` + arr.join(`</span> <span class="${span_class}">`) + '</span>';
            }
        },
        created() {
            this.getFuelSlected();
        }
    }
</script>

<style lang="scss">
    @import './src/assets/scss/components/stella/stella.scss';
</style>