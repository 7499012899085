<template>
  <div class="sidebar">
    <div class="sidebar__item chart__inner_min_max_avg"
        @mouseover="stopAutoChange(true)"
        @mouseleave="stopAutoChange(false)"
    >
      <h3 
        class="sidebar__item__title"
        v-if="chartdata"
      >
        Роздріб, грн.л {{currentFuelName}}
        <span 
          class="arrow arrow-right"
          @click="showNextFuel()">></span>
      </h3>
      <core-chart
        v-if="chartdata"
        :chartData="chartdata" 
        :options="options"       
        />
    </div>
    <div class="sidebar__item no-padding bordered">
      <div class="adv__exam adv__exam_300x600">
        <span>300x600</span>
      </div>
    </div>
    <!-- <div class="sidebar__item">
      <img src="/assets/images/kat1.gif" alt="">
    </div> 
    <div class="sidebar__item">
      <img src="/assets/images/kat2.gif" alt="">
    </div>  -->
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    data: () => ({
      currentFuel: 0,
      autoChange: true,
      stoped: false,
      chartLoaded: false,
      chartsData: {}
    }),
    computed: {
      currentFuelName() {
        return this.chartsData.fuels[this.currentFuel]
      },
      chartdata() {        
        if(!this.chartLoaded){
          return false;
        }

        return {
          labels: this.chartsData.dates,
          datasets: [
            {
              label: 'Мін',
              data: this.currentMin,
              backgroundColor: 'rgb(0, 112, 201, .4)',
              borderColor: 'rgba(0, 112, 201, 1)',
              borderWidth: 1
            },
            {
              label: 'Макс',
              data: this.currentMax,
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderColor: 'rgba(0, 0, 0, 1)',
              borderWidth: 1
            },
            {
              label: 'Середн',
              data: this.currentAvg,
              backgroundColor: 'rgba(255, 173, 51, 0.2)',
              borderColor: 'rgba(255, 173, 51, 1)',
              borderWidth: 1
            }
          ]
        }
      },
      currentMin() {
        return this.chartsData[this.currentFuelName].min
      },
      currentMax() {
        return this.chartsData[this.currentFuelName].max
      },
      currentAvg() {
        return this.chartsData[this.currentFuelName].avg
      },                  
      options() {
        if(!this.chartLoaded){
          return false;
        }
        return {
          title: {
            display: false,
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              fontSize: 10,
              boxWidth: 15,
              padding: 10
            }
          },
          tooltips: {
            // enabled: false
          },
          responsive: true,
          maintainAspectRatio: true
        }
      }
    },
    methods: {
      stopAutoChange(stop) {
        this.autoChange = !this.stoped && !stop ? true : false;
      },
      showNextFuel() {
        const m = this.chartsData.fuels.length;
        let n = this.currentFuel;
        n++;
        n = n >= m ? 0 : n;
        this.currentFuel = n;

      }
    },
    created() {
      axios
        .get('/assets/data/quotes.json')
        .then(response => {
          this.chartsData = response.data
        })
        .catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.chartLoaded = true));
    },
    mounted() {
      setInterval(() => {
        if (this.chartLoaded && this.autoChange) {
          this.showNextFuel();
        }
      }, 2000);
    }
  }
</script>
<style lang="scss">
  @import './src/assets/scss/components/sidebar.scss';
</style>