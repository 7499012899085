<template>
  <header>
    <div id="header">
      <div class="container">
        <ul class="header-menu">
          <li class="logo__inner"></li>
          <li v-for="(item, index) in links" class="header-menu__item" :key=index>          
            <router-link 
                v-if='item.routerLink'
                :to='item.url'
                :exact='item.exact'
              >{{item.name}}
            </router-link>
            <a v-if='!item.routerLink'
              :href='item.url'>
                {{item.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div id="header__inform" v-if="headerTicker">
      <div class="container_">
        <marquee behavior="scroll" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();">
          <span v-html="headerTickerRepeated"></span></marquee>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    props: {
      headerTicker: {
        type: String,
        default: null
      }
    },
    data: () => ({
      links: [
        { name: 'Україна', url: '/', routerLink: true, exact: true },
        { name: 'Області', url: '/regions', routerLink: true },
        { name: 'Бренди', url: '/brends', routerLink: true },
        { name: 'Паливо', url: '/fuels', routerLink: true },
        { name: 'Траси', url: '/roads', routerLink: true }
      ]
    }),
    computed: {
      headerTickerRepeated() {
        let tmpStr = this.headerTicker;
        tmpStr = tmpStr ? Array(10).fill(tmpStr).join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;') : '';
        return tmpStr;
      }
    }
  }
</script>

<style lang="scss">
  @import './src/assets/scss/sections/header.scss';
</style>
