<template>
  <div class="fuel-tabs">
    <span class="btn-close btn-close__filters" 
      @click="hideModal()"
    ></span>
    <div class="groups-label" v-if="fuelList">
      <a class="groups-label__item"
          href="#"
          v-for="tab in fuelList"
          :class="{ active: tab.id == activeTab }"
          :key="tab.id"
          @click.prevent="selectTab(tab.id)"
      >
        <span class="tab-title">{{tab.name}}</span>

        <span class="tab-status"
          href="#"
          :class="tabStatus[tab.id - 1] === 0 ? 'full': ''"
          @click.prevent="toggleSelectAll(tab.id, tabStatus[tab.id - 1])"
          :title="tabStatus[tab.id - 1] === 0 ? 'зняти всі': 'обрати всі'"
        >
          <svg class="tab-status__progress" width="20" height="20" 
            viewPort="0 0 10 10\0" 
            version="1.1" xmlns="http://www.w3.org/2000/svg">

            <circle r="9" cx="10" cy="10" 
              fill="transparent" 
              stroke-dasharray="56.548" 
              stroke-dashoffset="0"
            ></circle>
            <circle class="bar" r="9" cx="10" cy="10" 
              fill="transparent" 
              stroke-dasharray="56.548" 
              :stroke-dashoffset="tabStatus[tab.id - 1]"
            ></circle>
          </svg>

          <!-- <svg 
            class="tab-status__progress" x="0px" y="0px"
            viewBox="0 0 98.5 98.5" enable-background="new 0 0 98.5 98.5" xml:space="preserve">
            <path class="bar" fill="none" stroke-width="8" stroke-miterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4
            C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/>
          </svg> -->
        </span>

      </a>  
    </div>

    <div class="tabs-content"
      v-for="fuels in fuelList"
      :id="'fueltab__' + fuels.id"
      :key="fuels.id"
      :class="{'active' : fuels.id == activeTab, 'tab--opened_m': fuels.selected}"  
    >
      <div class="tabs-title"
        @click="fuels.selected = !fuels.selected"
      >
        <span class="title">
          {{fuels.title}} 
        </span>
        <span class="btn-close"></span>
      </div>

      <div class="tabs-content__unit tab-fuel-list stella"
        href="#"
        v-for="fuel in fuels.fuels"
        :key="fuel.id"
      >
        <input
            type="checkbox"
            :id="'fuel_' + fuel.id"
            :value="fuel.id" 
            v-model="fuel.selected"
        />

        <label
          class="stella__item"
          :class="'stella__item__' + fuel.id"
          :for="'fuel_' + fuel.id"
          :key="fuel.id"
          @click="updateMap"
        >
          <span>{{fuel.name}}</span>
        </label>


      </div>
    </div>      
  </div>
</template>

<script>
  let getDataDelay;

  export default {
    data: () => ({
      fuelList: {},
      fuelSelected: []
    }),

    methods: {
      selectTab(id) {
        // this.activeTab = id;
        this.$store.dispatch('selectFuelTab', id);
      },
      toggleSelectAll(id, selected) {
        let select = selected > 0 ? true : false;
        // const items = this.fuelList[id - 1].fuels;

        let n = 1;
        this.fuelList[id - 1].fuels.forEach((item, index) => {
            setTimeout(() => {
              item.selected = select;
              n++;
            }, (50 * (index + 1)));                      
        });

        setTimeout(() => {
          this.updateMap();
        }, (50 * (n + 1)));   
        
      },
      updateMap() {
        clearTimeout(getDataDelay);
        this.$parent.loaded = false;
        this.$parent.regDataLoaded =false;

        getDataDelay = setTimeout(() => {
          var fuelSelected = this.fuelSelected.length ? this.fuelSelected : [999];

          this.$store.dispatch('createFuelList', this.fuelList);
          this.$store.dispatch('updateFuelSelected', fuelSelected);

          this.$parent.updateMapData(fuelSelected);
          this.$parent.tableUpdate(fuelSelected);
          this.$parent.loaded = true;
        }, 800);
        
      },
      hideModal() {
        this.$parent.isFilter = false;
      },
      setFuelSelected(fuelSelected) {
        this.fuelSelected = fuelSelected;
      }
    },

    computed: {
      activeTab() {
        return this.$store.getters.getActiveGroup;
      },
      tabStatus() {
        const r = 9;
        const c = Math.PI*(r*2);
        const statuses = [];
        const fuelSelected = [];
        
        this.fuelList.forEach(group => {
          const fuels = group.fuels;
          let count = fuels.length;
          let active = 0;

          fuels.forEach(fuel => {
            if (fuel.selected) {
              active++;
              fuelSelected.push(fuel.id);

            }
          });

          let pct = (1 - (active / count)) * c;
          statuses.push(pct);
        });
        
        this.setFuelSelected(fuelSelected);
        // this.fuelSelected = fuelSelected;

        return statuses;
      }
    }, 
    created() {
      const waitingData = setInterval(() => {
        this.fuelList = this.$store.getters.getFuelList;
        if (this.fuelList.length) {
            clearInterval(waitingData);
        }     
      }, 100);
    }
  }
</script>

<style lang="scss">
  @import './src/assets/scss/sections/index/tabs.scss';
</style>
