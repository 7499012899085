//styles from https://snazzymaps.com/
const mapSettings = {
  center: { lat: 48.634723, lng: 31.247863 },
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: true,
  rotateControl: false,
  fullscreenControl: true,
  disableDefaultUi: false,
  minZoom: 6,
  maxZoom: 18,
  styles_: [
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#444444"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "color": "#f2f2f2"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "lightness": 45
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#cbcbcb"
        },
        {
          "visibility": "on"
        }
      ]
    }
  ]
};

const clusterStyles = [
  { "url": "https://www.okko.ua/images/map/m1.png", "width": 65, "height": 65, "zIndex": 0, "textColor": "red", "textSize": 20, "anchorIcon": [35, 35]},
  { "url": "https://www.okko.ua/images/map/m1.png", "width": 65, "height": 65, "zIndex": 0, "textColor": "blue", "textSize": 11 },
  { "url": "https://www.okko.ua/images/map/m1.png", "width": 65, "height": 65, "zIndex": 0, "textColor": "#fff", "textSize": 12 }
];

const clusterOptions = {
  imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  // styles: clusterStyles,
  maxZoom: 13
};

const icons = {
  "3": "https://wog.ua/images/gmarker_gasstation.png",
  "6": "https://www.okko.ua/map/pin.png",
  "4": {
    url: '/assets/images/icons/brands/shell.svg',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "5": {
    url: '/assets/images/icons/brands/anp.png',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "7": {
    url: '/assets/images/icons/brands/klo.svg',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "10": {
    url: '/assets/images/icons/brands/ukrnafta.png',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "16": {
    url: 'https://parallel.ua/local/templates/parallel/images/map_place.png',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "21": {
    url: '/assets/images/icons/brands/tatnafta.svg',
    size: { width: 15, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 15, height: 30, f: 'px', b: 'px' }
  },
  "23": {
    url: '/assets/images/icons/brands/avias.png',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "28": {
    url: 'https://brsm-nafta.com/wp-content/themes/brsm/images/list-marker_map_1.png',
    size: { width: 36, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 36, height: 36, f: 'px', b: 'px' }
  },
  "50": {
    url: 'https://socar.ua/img/map-marker.svg',
    size: { width: 36, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 36, height: 36, f: 'px', b: 'px' }
  },
  "58": {
    url: '/assets/images/icons/brands/olas.png',
    size: { width: 30, height: 11, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 11, f: 'px', b: 'px' }
  },
  "91": {
    url: 'https://www.glusco.swiss/html/assets/icons/map-pin.png',
    size: { width: 27, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 27, height: 36, f: 'px', b: 'px' }
  },
  "8": {
    url: 'https://neftek.ua/wp-content/uploads/2018/12/marker_map.png',
    size: { width: 35, height: 41, f: 'px', b: 'px' },
    scaledSize: { width: 35, height: 41, f: 'px', b: 'px' }
  },
  "96": {
    url: '/assets/images/icons/brands/chipo.png',
    size: { width: 27, height: 27, f: 'px', b: 'px' },
    scaledSize: { width: 27, height: 27, f: 'px', b: 'px' }
  },
  "73": {
    url: 'https://upg.ua/wp-content/themes/the23design/img/marker.svg',
    size: { width: 36, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 36, height: 36, f: 'px', b: 'px' }
  },
  "84": {
    url: 'https://amicenergy.com.ua/amic-data/public/site/themes/amic/assets/images/mark.svg',
    size: { width: 36, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 36, height: 36, f: 'px', b: 'px' }
  },
  "83": {
    url: 'https://sunoil.org/wp-content/themes/sunoil-website/assets/img/pin.png',
    size: { width: 28, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 28, height: 36, f: 'px', b: 'px' }
  },
  "87": {
    url: '/assets/images/icons/brands/motto.png',
    size: { width: 28, height: 28, f: 'px', b: 'px' },
    scaledSize: { width: 28, height: 28, f: 'px', b: 'px' }
  },
  "102": {
    url: '/assets/images/icons/brands/f-plus.png',
    size: { width: 30, height: 30, f: 'px', b: 'px' },
    scaledSize: { width: 30, height: 30, f: 'px', b: 'px' }
  },
  "0": {
    url: '/assets/images/icons/marker.svg',
    size: { width: 36, height: 36, f: 'px', b: 'px' },
    scaledSize: { width: 36, height: 36, f: 'px', b: 'px' }
  }
}

export { mapSettings, icons, clusterStyles, clusterOptions};
