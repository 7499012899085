<template>
    <div id="map" ref="map"></div>  
</template>

<script>
  import { Loader } from '@googlemaps/js-api-loader';
  import MarkerClusterer from '@googlemaps/markerclustererplus';
  import { mapSettings, icons, clusterOptions } from '../../constants/mapSettings';
  import axios from 'axios';

  export default {
    name: "GoogleMap",
    props: {
      markers: {
        type: Array,
        default: function() {
          return []
        }
      },
      activeAZS: {
        type: Number,
        defalt: 0
      },
      idRay: {
        type: String,
        default: null
      },
      apiKey: {
        type: String,
        default: null
      }

    },
    data() {
      return {
        map: null,
        mapMarkers: [],
        azsList: [],
        infowindow: null,
        markerCluster: null,
        activeMarker: 0,
        iconDefault: {
          url: '/assets/images/icons/marker.svg',
          size: {width: 46, height: 46, f: 'px', b: 'px'},
          scaledSize: {width: 46, height: 46, f: 'px', b: 'px'}
        },
        mapOptions: mapSettings,
        errors: []
      }
    },
    mounted() {
      const loader = new Loader({
        apiKey: this.apiKey,
        version: "weekly",
        language: 'uk'
      });
      this.mapOptions = mapSettings;
      this.updateMapParams();

      loader.load().then(() => {        
        this.initMap();
        this.initInfoWindow();
        // this.initMarkers();
      });
    },
    watch: {
      markers() {
        this.azsList = this.markers;
        // console.log(this.azsList);
        this.initMarkers();
      },
      idRay() {
        // console.log(this.idRay);
      }
    },
    methods: {
      initMap() {
        this.map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: 3,
          ...this.mapOptions
        });

        this.map.addListener("click", () => {
          this.$parent.isFilter = false;
        });
      },
      updateMapParams() {
        const idRay = this.idRay,
              wv = window.innerWidth;
        let zoomDiff = 0, 
            zoom = 6;

        if (wv < 480) {
          zoomDiff = -1;        
        } else if (wv > 2400) {
          zoomDiff = 1; 
        }

        if (idRay) {
          const url = '//dev.uapetrol.com/scripts/getRegPos.php';

          axios
          .post(url, { ray: idRay })
          .then(response => {
            // console.log(response);
            let mapParams = response.data;

            if(mapParams && mapParams.zoom) {
              zoom = mapParams.zoom;
              this.mapOptions.center = { lat: mapParams.lat, lng: mapParams.lng };
            }
     
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.mapOptions.zoom = zoom + zoomDiff;
            this.setMapOptions();
          });
        } else {
          this.mapOptions.zoom = zoom + zoomDiff;
        }
      },
      setMapOptions() {
        if (this.map) {
          // console.log(this.mapOptions);
          this.map.setOptions(this.mapOptions);
        }
      },
      getMarkerByID(idAZS) {
        let marker = this.mapMarkers.find(obj => { return obj.idAZS === idAZS });
        return marker;
      },
      toogleMarker(marker, show) {
        //if (!marker.getVisible()) {
            marker.setVisible(show);
        //} else {
            //marker.setVisible(false);
        //}
      },
      deleteAllMarkers() {
        if (!this.mapMarkers.length) {
          return;
        }

        this.mapMarkers.forEach(element => {
            this.markerCluster.removeMarker(element, true);
        });

        this.markerCluster.clearMarkers();
      },
      initMarkers() {
        const markers2 = this.azsList;
        // console.log('initMArkers');
        this.deleteAllMarkers();

        const markers = markers2.map((marker) => {
          let icon = icons[marker.pic] ? icons[marker.pic] : icons[0];
          let image = icon.size2 ? 
                        { 
                          url: icon.url, 
                          size: new window.google.maps.Size(icon.size.width, icon.size.height), 
                          origin: new window.google.maps.Point(icon.size.width, icon.size.height), 
                          anchor: new window.google.maps.Point(0, icon.size.height) 
                        } : icon;

          let point = new window.google.maps.Marker({
            position: marker.pos,
            icon: image,
            idAZS: marker.id
          });
          
          point.addListener("mouseover", () => {
            this.updateInfoWindow(marker);
            this.infowindow.open(this.map, point);
          });

          point.addListener("click", () => {
            this.loadAzsDataFor(marker);
            this.infowindow.open(this.map, point);
          });

          point.addListener("mouseout", () => {
            // this.infowindow.close();
          });
          return point;
        });
        this.mapMarkers = markers;
        this.initClusterer(markers);
      },
      initClusterer(markers) {
        this.markerCluster = new MarkerClusterer(this.map, markers, clusterOptions);
      },
      updateClusterer(marker) {
        this.markerCluster.addMarker(marker);
      },
      initInfoWindow() {
        this.infowindow = new window.google.maps.InfoWindow();
      },

      updateInfoWindow(marker) {
        if (this.activeMarker == marker.id) {
          return
        }

        let content = '';
        /*content = this.$store.getters.getAzs(marker.id);

        if (content) {
          this.infowindow.setContent(content);
          return;
        }*/

        this.activeMarker = marker.id

        content = `<h3 class="infowindow__title">${marker.hint.name}</h3>`;
        content += `<div class="infowindow__address">${marker.hint.text}</div>`;

        this.infowindow.setContent(`<div class="infowindow-wrap">${content}</div>`);
      },

      loadAzsDataFor(azs) {        
        let azsInfo = this.$store.getters.getAzs(azs.id);

        if (azsInfo) {
          this.infowindow.setContent(azsInfo);
          return;
        }

        const url = `//dev.uapetrol.com/scripts/getInfoWindow.php?azs=${azs.id}`;
        axios
        .get(url)
        .then(response => {
          // console.log(response.data);
          const azsData = response.data;
          
          let content = `<h2 class="infowindow__title">${azs.hint.name}</h2>`;
          content += `<div class="infowindow__address">${azs.hint.text}</div>`;

          const phone = azsData.phone;
          content += phone ? `<div class="infowindow__phone">Телефон: <a href='tel:+38${phone.replace(/\s+/g, '')}' class="infowindow__phone">${phone}</a></div>` : '';

          let services = '', stella = '';

          if (azsData.services && azsData.services.length) {
            azsData.services.forEach(service => {
              services += `<li class="services__item--${service.id}">${service.name}</li>`;
            });
            services = `<h3 class="infowindow__title">Сервіси</h3><ul class="services">${services}</ul>`;
          }

          if (azsData.stella) {
            stella = `<div class="stella-wrap">`
            stella += `<div class="brend-stella brend_${azs.pic}">`;
            stella += `<div  class="brend__logo"><div class="of-container"><img src="http://azs.uapetrol.com/images/brends/brend${azs.pic}.gif" class="of-container__image"/></div></div>`;            
            stella += `<ul class="brend__fuels">`;

            if ( azsData.stella.length == 0) {
              azsData.stella = [ 
                {
                  id: 0,
                  name: '',
                  price: '--.--'
                },
                {
                  id: 1,
                  name: '',
                  price: '--.--'
                },
                {
                  id: 2,
                  name: '',
                  price: '--.--'
                }
              ]
            }

            azsData.stella.forEach( element => {
              stella += `<li class="brend-stella__item brend-stella__item__${element.id}"><span class="label" title="${element.name}">${element.name}</span><span class="price">${element.price}</span></li>`;
            });

            stella += `</ul></div><div class="date">Ціни станом на: ${azsData.date}</span></div></div>`;
          }

          azsInfo = `<div class="infowindow-wrap">${content}${services}</div>${stella}`;

          this.$store.dispatch('addAZS', {id: azs.id, data: azsInfo});
          // this.$store.dispatch('updateMarkers', response.data.data);

          this.infowindow.setContent(azsInfo);

        })
        .catch(error => {
          console.log(error);
        });

      }
    }
  }
</script>