<template>
  <div class="comp__index_map">
    <div class="regionselector dropdown-area">
      <div class="dropdown-area"
        :class="{ active: regionSelector, 'mobile-view': isMob }"
      >
        <div class="dropdown-value"
          @click="dropRegions"
          @mouseover="clearTimeoutDropRegion"
          @mouseleave="countdownDropRegions()"        
        >
          <i>{{getRayName}}</i>
          <span class="arrow"></span>
        </div>

        <transition name="dropdown">
          <div class="as-dropdown"
            v-if="regionSelector"                            
            @mouseover="clearTimeoutDropRegion"
            @mouseleave="countdownDropRegions(500)"
          >
            
            <ul class="as-dropdown__list scroll-bar">
              <li 
                v-for="(region, index) in regionList"
                :key="index"
              >
                <a :href="'/regions/' + region.id" 
                    @click.prevent="selectRegion(region.id)"
                >
                  {{region.name}}
                </a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
    <div id="map-ukr" class="map-ukr__region"
      :class="(!regSVGLoaded ? 'waiting ' : '') + 'map-ukr__region--' + idReg "
    >   
 
        <svg version="1.1" 
          :viewBox="viewBox ? viewBox : '0 0 568 568'"
          preserveAspectRatio="xMinYMin meet"
        >
          <g v-if="regSVGLoaded" class="map-svg">    
            <path v-for="(ray, index) in rayList"
              class="map-svg__item"
              :key="index"
              :d="ray.d"
              :title="ray.title"
              :id="ray.id"
              :class="('m-s__i--' + index) + ' ' + getRayClass(ray.id)"
              @click="setInfoOf(ray.id)"
              @mouseover="showInfoOf(ray.id)"
              @mouseleave="setDefaltRay()"           
            >
              <title v-if="ray.title">{{ray.title}}</title>
            </path>
          </g>
      </svg>
    </div>

    <div v-if="false" style="z-index:9999999999">
      <textarea v-model="rays"></textarea>
      {{createObjFromSvg(74)}}
      <!-- {{regionsData}} -->
    </div>
    
    <div class="region-info"
      v-if="regionsData[activeRay]"
      :class="(!regSVGLoaded ? 'waiting ' : '') + ((activeRay && activeRay !=idReg) ? 'selected' : '')"
    >
      <a href="#" class="btn-close"
        @click.prevent.self="setDefaltRay(true)"
      >закрити</a>

      <h2 class="region__title">
        <span>{{regionsData[activeRay] ? regionsData[activeRay].regName : ''}}</span>
      </h2>

      <ul class="region__params_list" >
        <li class="divider">
            <span>Кількість АЗС: </span>
            <span class="value">{{regionsData[activeRay].kolAZS}}</span>
        </li>
        <li>
          <span>Кількість брендів: </span>
          <span class="value">{{regionsData[activeRay].kolBrends}}</span>
        </li>
        <li class="divider">
            <span>Мінімальна ціна: </span>
            <span class="value">{{regionsData[activeRay].minPrice}}</span>
        </li>
        <li>
          <span>Максимальна ціна: </span>
          <span class="value">{{regionsData[activeRay].maxPrice}}</span>
        </li>
        <li>
          <span>Зміна середньої <br/> ціни до <strong>{{regionsData.regInfo.chDate}}</strong>: </span>
          <span class="value">{{regionsData[activeRay].chPrice}}</span>
        </li>
        <li 
          v-if="isMob"
          :style="{ visibility: activeRay ? 'visible': 'hidden' }"
        >
          <a class="region__params_list__link link-arrow" 
            @click="showRoadRay(activeRay)"
          >Детальніше</a>
        </li>
      </ul>      

    </div><!-- region-info -->
    
    <ul class="map-color-info map-legend">
      <li class="nochanges">Немає даних</li>
    </ul>
    <ul class="map-price--steps"
      v-if="regionsData.regInfo"
    >
      <li class="price-steps__lime">{{regionsData.regInfo.stepPrice}}</li>
      <li class="price-steps__green">{{regionsData.regInfo.stepPrice}}</li>
      <li class="price-steps__green">{{regionsData.regInfo.stepPrice}}</li>
      <li class="price-steps__yellow">{{regionsData.regInfo.stepPrice}}</li>
      <li class="price-steps__red">{{regionsData.regInfo.stepPrice}}</li>
    </ul>
    <ul class="map-price--value"
      v-if="regionsData.regInfo"
    >
      <li>{{regionsData.regInfo.price1}}</li>
      <li>{{regionsData.regInfo.price2}}</li>
      <li>{{regionsData.regInfo.price3}}</li>
      <li>{{regionsData.regInfo.price4}}</li>
    </ul>
    
  </div>
</template>

<style lang="scss">
  @import './src/assets/scss/sections/index/map-ukr.scss';
</style>

<script>
  import axios from 'axios';
  let dropRegionsTimeout;
  export default {
    props: {
      regSVGLoaded: Boolean,
      viewBox: String,
      rayList: Array,
      regionList: Array,
      regionsData: Object,
      idReg: String
    },
    data: () => ({
      activeRay: 0,
      rays: '[]',
      url: '/roads?ray=',
      regionSelector: false
    }),
    computed: {
      isMob() {
        return window.isMob;
      },
      curRay() {
        return this.idReg;
      },
      getRayName() {
        var name = '';
        for(var i = 0; i < this.regionList.length; i++) {
          if (this.regionList[i].id == this.idReg) {
            name = this.regionList[i].name;
            break;
          }
        }
        return name;
      }
    },
    watch: {
      curRay() {
        this.activeRay = this.idReg;
      }
    },
    methods: {
      dropRegions() {
        this.regionSelector = !this.regionSelector;
      },
      selectRegion(id) {
        this.countdownDropRegions(0);
        this.$router.push('/regions/' + id);
      },
      clearTimeoutDropRegion() {
        clearTimeout(dropRegionsTimeout);
      },
      countdownDropRegions(timer) {
        timer = timer || timer === 0 ? timer : 1500;
        clearTimeout(dropRegionsTimeout);

        dropRegionsTimeout = setTimeout(() => {
          this.regionSelector = false;
        }, timer);

      },
      showInfoOf(idRay) {
        if (idRay == 'capital') {
          this.setDefaltRay();
          return;
        }
        this.activeRay = idRay;
      },
      setInfoOf(idRay) {
        if (idRay == 'capital') {
          return;
        }

        if(window.isMob) {
          this.showInfoOf(idRay);
        } else {
          // location.href = this.url + idRay;
          this.$router.push({ name: 'roads', query: { ray: idRay }})
        }        
      },
      showRoadRay(idRay) {
        this.$router.push({ name: 'roads', query: { ray: idRay }})
      },
      setDefaltRay(strong) {
        if(!window.isMob || strong) {
          this.activeRay = this.idReg;
        }
      },
      getRayClass(idRay) {
        return this.regionsData[idRay] ? 'bg_' + this.regionsData[idRay].color : 'bg_nodata'
      }, 
      createObjFromSvg(id) {
        const json = [],
              url = `/assets/images/svg/${id}.svg`;
        axios
        .get(url)
        .then(response => {

          const div = document.createElement("div");
          div.innerHTML = response.data;
          const svg = div.getElementsByTagName('svg')[0];
          const paths = svg.getElementsByTagName('path');

          for(let i = 0; i < paths.length; i++) {
            let d = paths[i].getAttribute("d").replace(/(\r\n|\n|\r)/gm, "");
            let id = paths[i].getAttribute("id");

            let ray = {
              id: id,
              d: d,
              title: ''
            };

            json.push(ray);
          }

          this.rays = '{"rays":' + JSON.stringify(json) + '}'; 

        })
        .catch(error => {
          this.rays  = JSON.stringify([{'error': error, 'url': url}]);
        });
      }
    },
    mounted() {
      this.activeRay = this.idReg;
    }
  }
</script>