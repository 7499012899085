export default {
    state:{
        regions: {
            0: {
                0: {

                }
            }
        },
        regionsData: {
            0: {
                0: {

                }
            }
        }
    },
    mutations: {
        mutateRegionsData(state, payload) {
            state.regionsData[payload.idReg] = payload.data;
        },
        mutateRegionMaps(state, payload) {
            state.regions[payload.idReg] = payload.data;
        }
    },
    actions: {
        addRegionMap({ commit }, payload) {
            commit('mutateRegionMaps', payload);
        },
        addRegionData({ commit }, payload) {
            commit('mutateRegionsData', payload);
        }
    },
    getters: {
        getRegionMap(state) {
            return state.regions;
        },
        getRegionsData(state) {
            return state.regionsData;
        }
    }
}