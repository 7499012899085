<template>
    <div class="billboard header-billboard">
      <div class="billboard__content">
        <div class="container">
          <h3 class="billboard__title">Моніторинг цін на АЗС</h3>
          <div class="billboard__subtitle">
            Масив даних в форматі Excel <br>
            Зміна рівня цін (мінімум, максимум, середнє)
          </div>
          <a target="_blank" rel="noopener" href="https://dzi.gov.ua/services/monitoring-azs/" class="billboard__link link-arrow">Замовити</a>
        </div>   
      </div>
      <div class="billboard__background">
        <a target="_blank" rel="noopener" href="https://dzi.gov.ua/services/monitoring-azs/" >
          <img 
            src="/assets/images/ipad-excel.jpg" 
            class="billboard-hero"
            width="1003" height="320"
            alt="Моніторинг цін на АЗС у форматі Excel"
          >
        </a>
      </div>  
    </div>
</template>

<script>
  export default {
    name: 'Billboard',
    metaInfo() {
      return {
        link: [
          { rel: 'preload', as: 'image', href: '/assets/images/ipad-excel.jpg' }
        ]
      }
    }
  }
</script>
