import Vue from 'vue';
import Vuex from 'vuex';

import petrols from './petrol';
import regions from './regions';
import brends from './brends';
import markers from './map';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        petrols,
        regions,
        brends,
        markers
    }
})