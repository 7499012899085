<template>
  <div id="toolbar"></div>
</template>
<script>
  import axios from 'axios';

  export default {
    data: () => ({
      fuelList: []
    }),
    mounted() {
      console.log('Index mounted');
    },
    created() {
      axios
        .get('./assets/data/fuel.json')
        .then(response => {
          this.fuelList = response.data.fuel.groups;
          console.log(response);
        })
        .catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  }
</script>