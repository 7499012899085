/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/
 */

// Lib imports
import Vue from 'vue';
import Router from 'vue-router';

// Routes
const ukr = () => import('./views/Index.vue');
const region = () => import('./views/Regions.vue');
const brends = () => import('./views/Brends.vue');
const fuels = () => import('./views/Fuels.vue');
const roads = () => import('./views/Roads.vue');
const notFound = () => import('./views/NotFound.vue');
 
Vue.use(Router);

// Create a new router
const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: ukr },
    { path: '/regions', component: region },
    { path: '/regions/:idReg', component: region },
    { path: '/brends', component: brends },
    { path: '/brends/:brendName', component: brends },
    { path: '/fuels', component: fuels },
    { path: '/fuels/:idFuel', component: fuels },
    { name: 'roads', path: '/roads', component: roads },
    { path: '/:pathMatch(.*)*', name: 'not-found', component: notFound },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

export default router
