<template>
  <div class="side-controls">
    <a class="btn-close btn-close__filters" 
      href="#"
      @click.prevent="hideModal()"
    ></a>
    <div class="groups-list scroll-bar--large">
      <div class="nav-group"
        :class="{'active' : controlsOpened[0]}"
      >
        <a class="nav-group__title"
          href="#"
          @click.prevent="toggleOpenGroup(0)"
        >
          <span class="title">
            Паливо
          </span>
          <span class="btn-close"></span>
        </a>
        <transition name="slide-fade">
          <ul
            v-if="controlsOpened[0]" 
            class="side-controls__block">
            <li 
                class="side-controls__content side-controls--has-child"
                v-for="fuels in fuelList"
                :key="fuels.id"
                :class="{'nav--opened': fuels.opened}"
            >
              <a href="#" 
                class="side-control__label"
                @click.prevent="toggleShowFuels(fuels.id)"
              >
                <span class="arrow"></span>
                {{fuels.title}}
                <span class="select_all"
                  @click.prevent.stop="toggleSelectAll(fuels.id, !isAllFuels[(fuels.id - 1)])"
                >
                  {{isAllFuels[(fuels.id - 1)] ? 'зняти всі' : 'обрати всі'}}
                </span>
              </a>
              <transition name="slide-fade">
                <ul 
                  class="side-controls__children"
                  v-if="fuels.opened"
                >
                  <li class="tabs-content__unit tab-fuel-list stella"
                    href="#"
                    v-for="fuel in fuels.fuels"
                    :key="fuel.id"
                  >
                    <input
                        type="checkbox"
                        :id="'fuel_' + fuel.id"
                        :value="fuel.id" 
                        v-model="fuel.selected"
                    />

                    <label
                      class="side-controls__item"
                      :for="'fuel_' + fuel.id"
                      :key="fuel.id"
                      @click="updateMap"
                    >
                      <span>{{fuel.name}}</span>
                    </label>
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </transition>
      </div>

      <div class="nav-group brands-groups"
        :class="{'active' : controlsOpened[1]}"
      >
        <a class="nav-group__title"
          href="#"
          @click.prevent="toggleOpenGroup(1)"
        >
          <span class="title">
            Бренди
          </span>
          <span class="select_all"
            @click.stop.prevent="toggleSelectBrands(!isAllBrands)"  
          >
            {{isAllBrands ? 'зняти всі' : 'обрати всі'}}
          </span>
          <span class="btn-close"></span>
        </a>

        <ul
          v-if="controlsOpened[1]" 
          class="side-controls__block scroll-bar--large">
          <li 
              class="side-controls__content tabs-content__unit tab-fuel-list stella"
              v-for="brand in brandsList"
              :key="brand.id"
          >
            <input
                type="checkbox"
                :id="'brand_' + brand.id"
                :value="brand.id" 
                v-model="brand.selected"
            />

            <label
              class="side-controls__item"
              :for="'brand_' + brand.id"
              :key="brand.id"
              @click="updateMap()"
            >
              <span>{{brand.name}}</span>
            </label>

          </li>
        </ul>

      </div>

      <div class="nav-group">
       <ul
          class="side-controls__block">
          <li 
              class="side-controls__content tabs-content__unit tab-fuel-list stella"
          >
             <input
                type="checkbox"
                id="price_only"
                v-model="price_only"
            />

            <label
              class="side-controls__item price_only"
              for="price_only"
              @click="updateMap()"
            >
              <span>Лише з цінами</span>
            </label>

          </li>
       </ul>
      </div>
    </div>
  </div>
</template>

<script>
  let dataDelay;
  
  import axios from 'axios';

  export default {
    data: () => ({
      fuelList: {},
      brandsList: [],
      fuelSelected: [],
      brandsSelected: [],
      price_only: true,
      controlsOpened: [false, true, false]
    }),
    created() {
      const waitingData = setInterval(() => {
        this.fuelList = this.$store.getters.getFuelList;
        if (this.fuelList.length) {
            clearInterval(waitingData);
            this.updateFuelSelected();
        }     
      }, 100);

      axios
      .get(`/assets/data/getBrands.json`)
      .then(response => {
        this.brandsList = response.data;
        this.updateBrandsSelected();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.updateMap();
      });
    },
    computed: {
      isAllFuels() {
        const fuelSelected = [];
        let all = true;
        
        this.fuelList.forEach(group => {
          all = true;
          const fuels = group.fuels;

          for (let el of fuels) {
            if (!el.selected) {
              all = false;
              break;
            }
          }

          fuelSelected.push(all);
        });     

        return fuelSelected;
      },      
      isAllBrands() {
        let all = true;

        for (let el of this.brandsList) {
          if (!el.selected) {
            all = false;
            break;
          }
        }

        return all;
      }
    },
    methods: {
      toggleOpenGroup(id) {
        const opened = [... this.controlsOpened];
        opened[id] = !opened[id];
        console.log(opened);
        this.controlsOpened = opened;
      },
      toggleShowFuels(id) {       
        const fuelList =  [...this.fuelList],
              opened = fuelList[id - 1].opened ? false : true;

        fuelList[id - 1].opened = opened;
        this.fuelList = fuelList;
      },
      toggleSelectAll(id, selected) {
        let select = selected > 0 ? true : false;
        let n = 1;

        this.fuelList[id - 1].fuels.forEach((item, index) => {
            setTimeout(() => {
              item.selected = select;
              n++;
            }, (50 * (index + 1)));                      
        });

        setTimeout(() => {
          this.updateMap();
        }, (50 * (n + 1)));  
        
      },
      updateMap(timeout) {
        timeout = timeout  || timeout === 0 ? timeout : 800;

        clearTimeout(dataDelay);
        // this.$parent.loaded = false;
        this.$parent.regDataLoaded =false;

        dataDelay = setTimeout(() => {
          this.updateFuelSelected();
          this.updateBrandsSelected();
          const fuelSelected = this.fuelSelected.length ? this.fuelSelected : [999];
          const brandsSelected = this.brandsSelected.length ? this.brandsSelected : [999];
          const allAZS = this.price_only ? false : true;

          this.$store.dispatch('createFuelList', this.fuelList);
          this.$store.dispatch('updateFuelSelected', fuelSelected);

          this.$store.dispatch('updateSelectedBrands', brandsSelected);
          this.$store.dispatch('updateAllAZS', allAZS);

        }, timeout);        
      },      
      toggleSelectBrands(selected) {
        let select = selected > 0 ? true : false;
        let n = 1;

        this.brandsList.forEach((item, index) => {
            setTimeout(() => {
              item.selected = select;
              n++;
            }, (10 * (index + 1)));                      
        });
        
        setTimeout(() => {
          this.updateMap();
        }, (50 * (n + 1))); 
      },
      hideModal() {
        this.$parent.isFilter = false;
      },
      updateFuelSelected() {
        const fuelSelected = [];
        this.fuelList.forEach(group => {
          const fuels = group.fuels;

          fuels.forEach(fuel => {
            if (fuel.selected) {
              fuelSelected.push(fuel.id);
            }
          });
        });
        
        this.fuelSelected = [...fuelSelected];        
      },
      updateBrandsSelected() {
        const brandsSelected = [];
        this.brandsList.forEach(brand => {

          if (brand.selected) {
            brandsSelected.push(brand.id);
          }

        });
        
        this.brandsSelected = [...brandsSelected];        
      },
    }
  }
</script>

<style lang="scss">
  @import './src/assets/scss/sections/index/map-controls.scss';
</style>
