export default {
    state:{
        brends: {

        }
    },
    mutations: {
        mutateBrendsData(state, payload) {
            state.brends[payload.idBrend] = payload.data;
        },
        mutateBrendFuelSelected(state, payload) {
            if (!state.brends[payload.idBrend]) {
                state.brends[payload.idBrend] = {};
            }
            state.brends[payload.idBrend].fuelSelected = payload.data;
        }
    },
    actions: {
        addBrend({ commit }, payload) {
            commit('mutateBrendsData', payload);
        },
        updateBrendFuelSelected({ commit }, payload) {
            commit('mutateBrendFuelSelected', payload);
        }
    },
    getters: {
        getBrendData(state) {
            return state.brends;
        }
    }
}