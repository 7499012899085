import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: "UA-4192878-3"
  }
});

import './components';

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
