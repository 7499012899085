<template>
  <div id="wrap"
    v-if="loaded"
  >
    
    <core-header 
      :headerTicker="main.headerTicker" />

    <core-body />

    <core-footer />

  </div>
</template>

<style lang="scss">
  @import './src/assets/scss/app.scss';
  @import './src/assets/scss/sections/ukraine.scss';  
</style>

<script>
  import axios from 'axios'
  import CoreHeader from './components/core/Header'
  import CoreBody from './components/core/Body'
  import CoreFooter from './components/core/Footer'
  
  export default {
    metaInfo() {
      return {
        title: 'АЗС України',
        meta: [
          { name: 'description', content:  'Середні ціни на бензин, дизельне паливо і скраплений газ на АЗС України. Достовірна інформація про роздрібному ринку нафтопродуктів України. Найповніший охоплення брендів і марок палива.'},
          { property: 'og:title', content: "АЗС України - Середні ціни на бензин по Украіні"},
          { property: 'og:site_name', content: 'АЗС України'},
          {property: 'og:type', content: 'website'},    
          {name: 'robots', content: 'index,follow'} 
        ]
      }
    },
    data: () => ({
      loaded: false,
      fuelSelected: [],
      main: {},
      errors: []
    }),
    components: {
      CoreHeader,
      CoreBody,
      CoreFooter
    },
    methods: {
      appInit(){
        this.isMobile();
      },
      isMobile(){
        if( navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)){

          if(navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)){
            window.ios = true;

          } else if(navigator.userAgent.match(/Android/i)){
            window.android = true;
          } 
          window.isMob = true;
        } else {
          window.isMob = false;
        }
      }
    },
    created() {
      this.appInit();
      // window.addEventListener('resize', function() {
      //   this.getWindowWidth();
      // });
      // alert('app');

      //FuelsList
      axios
        .get('/assets/data/fuel.json')
        .then(response => {
          const fuelList = response.data.fuels[0].groups;
          this.$store.dispatch('createFuelList', fuelList);
          this.loaded = true;
        })
        .catch(error => {
          console.log(error);
          this.errors.push(error)
      });
      axios
        .get('/assets/data/main.json')
        .then(response => {
          this.main = response.data;
        })
        .catch(error => {
          console.log(error);
          this.errors.push(error)
        });
    }
  }
</script>
