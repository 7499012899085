export default {
    state:{
        fuelList: [],
        activeGroup: 1,
        fuelSelected: []
    },
    mutations: {
        mutateFuelList (state, payload) {
            state.fuelList = payload;
        },
        mutateFuelSelected (state, payload) {
            state.fuelSelected = payload;
        },
        mutateActiveGroup (state, payload) {
            state.activeGroup = payload;
        }
    },
    actions: {
        createFuelList ({commit}, payload) {
            commit('mutateFuelList', payload);
        },
        updateFuelSelected ({commit}, payload) {
            commit('mutateFuelSelected', payload);
        },
        selectFuelTab ({commit}, payload) {
            commit('mutateActiveGroup', payload);
        }
    },
    getters: {
        getFuelList (state) {
            return state.fuelList
        },
        getFuelSelected (state) {
            return state.fuelSelected
        },
        getActiveGroup (state) {
            return state.activeGroup;
        }
    }
}