<template>
    <div class="grid__inner">
        <span v-show="!loaded">loading.....</span>
        <div class="table"
            v-if="loaded"
            :class = "gridClass"
        >
            <div class ="tr">
                <div class="th grid__filter" v-for="column in columns"
                    @click="sortBy(column.name)"
                    :key="column.name"
                    :class="{ active: sortKey == column.name }"
                >
                    <span 
                        class="label"
                        :class="'label_for_' + column.name"
                    >
                        {{  column.label }}
                    </span>
                    <span 
                        class="arrow" 
                        :class="sortOrders[column.name] > 0 ? 'asc' : 'dsc'"
                    >
                    </span>
                </div>
            </div>
            <div class="tr" v-for="(entry, index ) in filteredGrid"
                :key="index"
            >
                <div class="td" v-for="(column, index) in columns"
                    :key="column.name"
                >
                    <span
                        :class="(index===0 && url)? 'has__link preudo__link' : ''"
                    >
                        <a class="link__absolute"
                            v-if="!routerLink && index===0 && url"
                            :href="createGridUrl(entry)"
                        ></a>
                        <router-link 
                            v-if="routerLink && index===0 && url"
                            class="link__absolute"
                            :to='createGridUrl(entry)'>
                        </router-link>
                        {{entry[column.name]}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dataList: Array,
            labels: Array,
            columns: Array,
            filterKey: String,
            url: String,
            urlData: String,
            columnsCount: Number,
            routerLink: {
                type: Boolean,
                default: false
            } 
        },
        data: function () {
            var sort = {};
            this.columns.forEach(function(column) {
                sort[column.name] = 1
            });

            return {
                sortKey: '',
                loaded: false,
                gridData: [],
                sortOrders: {}
            }
        },
        computed: {
            filteredGrid() {
                var sortKey = this.sortKey;
                var filterKey = this.filterKey && this.filterKey.toLowerCase();
                var order = this.sortOrders[sortKey] || 1;
                var dataList = this.dataList;

                if (filterKey) {
                    dataList = dataList.filter(function (row) {
                        return Object.keys(row).some(function (key) {
                            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                        })
                    })
                }

                if (sortKey) {
                    dataList = dataList.slice().sort(function (a, b) {
                        a = a[sortKey];
                        b = b[sortKey];
                        return (a === b ? 0 : a > b ? 1 : -1) * order;
                    })
                }

                return dataList;
            },
            gridClass() {
                return this.columnsCount ? 'column__vars columns_' + this.columnsCount : ''; 
            }
        },
        filters: {
            capitalize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }
        },
        methods: {
            sortBy(key) {
                this.sortKey = key;

                if (this.sortOrders[key] === undefined) {
                    this.sortOrders[key] = 1;
                }
                this.sortOrders[key] = this.sortOrders[key] * -1;
            },
            createGridUrl(entry) {
                return this.urlData ?  this.url + entry[this.urlData] : this.url + entry.id;
            }
        },
        mounted() {
            var waitingData = setInterval(() => {
                if(this.columns.length > 1) {
                    var sort = {};
                    this.columns.forEach( column => {
                        sort[column.name] = 1
                    });

                    clearInterval(waitingData);
                    this.loaded = true;
                    this.sortOrders = sort;
                }
                
            }, 500);
        }
    }
</script>

<style lang="scss">
  @import './src/assets/scss/components/table-grid.scss';
</style>